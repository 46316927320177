import { BrowserWindow, Experiments } from '@wix/thunderbolt-symbols'

// we need to import from thunderbolt-commons src because of tree shaking issue that makes lodash to be undefined
// eslint-disable-next-line
import { isWindows } from '@wix/thunderbolt-commons/src/deprecatedBrowserUtils'

export const prefersReducedMotion = (browserWindow: BrowserWindow, experiments: Experiments, requestUrl = '') => {
	const isReducedMotionExperimentOpen = experiments['specs.thunderbolt.reducedMotion']
	const isWindowsOS = isWindows(browserWindow!) // todo - this will later depend on UoU decision using a new a11y prompt
	const shouldForce = requestUrl.toLowerCase().includes('forcereducedmotion')
	return (
		shouldForce ||
		(browserWindow && isReducedMotionExperimentOpen && !isWindowsOS
			? browserWindow.matchMedia('(prefers-reduced-motion: reduce)').matches
			: false)
	)
}
